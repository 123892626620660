import { Component } from "react";

// import { Row, Col, Card, Form, Input, Button } from "antd";
// import { Container, Row, Col, Card } from "react-bootstrap";

import {
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  TextField,
  Divider
} from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
// import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

class APIPage extends Component {
  render() {
    return (
  		<Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: 2 }}>
  			<Grid item md={6} xs={12}>
					<Card sx={{ textAlign: "center", marginBottom: 2 }} variant="outlined">
            <CardContent>
              <Typography variant="h5">API Settings</Typography>
              <Divider sx={{ marginY: 2 }} />
  						<form onSubmit={this.props.saveAPISettings}>
                <TextField
                  required
                  fullWidth
                  variant="filled"
                  name="brokerCode"
                  label="Broker Code"
                  sx={{ marginTop: 1 }}
                  value={this.props.brokerCode}
                  onChange={this.props.handleAPIEdit}
                />

                <TextField
                  required
                  fullWidth
                  variant="filled"
                  name="brokerUsername"
                  sx={{ marginTop: 1 }}
                  label="Broker Username"
                  value={this.props.brokerUsername}
                  onChange={this.props.handleAPIEdit}
                />

                <TextField
                  required
                  fullWidth
                  name="apiKey"
                  label="API Key"
                  variant="filled"
                  sx={{ marginTop: 1 }}
                  value={this.props.apiKey}
                  onChange={this.props.handleAPIEdit}
                />

                <TextField
                  required
                  fullWidth
                  name="apiSecret"
                  variant="filled"
                  label="API Secret"
                  sx={{ marginTop: 1 }}
                  value={this.props.apiSecret}
                  onChange={this.props.handleAPIEdit}
                />

                <Button
                  type="submit"
                  color="primary"
                  disableElevation
                  variant="contained"
                  startIcon={<SaveIcon />}
                  sx={{ marginTop: 2 }}
                >
                  Save Settings
                </Button>
  						</form>
            </CardContent>
					</Card>
  			</Grid>
  		</Grid>
    );
  }
}

export default APIPage;
