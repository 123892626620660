import "./App.css";
// import "./output.css";
import Cookies from "js-cookie";
import { Component } from "react";
import { toast } from "react-toastify";
// import "bootstrap/dist/js/bootstrap.min.js";
// import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import CssBaseline from "@mui/material/CssBaseline";
import "react-confirm-alert/src/react-confirm-alert.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// ---------------------------------------------------------------
// Components

import APIPage from "./components/apiPage.jsx";
import MainPage from "./components/mainPage.jsx";
import LoginForm from "./components/loginForm.jsx";
import RegisterForm from "./components/registerForm.jsx";
import ResetPasswordForm from "./components/resetPasswordForm.jsx";
import ForgotPasswordForm from "./components/forgotPasswordForm.jsx";

// ---------------------------------------------------------------

// import "./App.less";

const qs = require("query-string");

toast.configure()

const options = {
  position: "bottom-right",
  pauseOnHover: true,
  closeOnClick: true,
  theme: "dark",
  autoClose: 2500
}

const theme = createTheme({
  palette: {
    background: {
      default: "#F5F5F5"
    },
    primary: {
      main: "#af0000"
    },
    secondary: {
      main: "#001529"
    }
  }
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: localStorage.getItem("jwt-token") ? true : false,
      username: "",
      brokerCode: "",
      apiKey: "",
      apiSecret: "",
      rules: [],
      trades: [],
      orders: [],
      positions: [],
      logRuleID: null,
      logsWS: null,
      showLogs: false,
      logs: [],
      bdate: null,
      bdateWS: null,
      // APIPageStatus: "not shown"
      showAPIPage: false,
      formToShow: "login"
    }

    console.log(process.env.NODE_ENV);

    this.URL = process.env.NODE_ENV === "production" ? "" : "http://localhost:8000";
    console.log(this.URL);

    this.WS_URL = process.env.NODE_ENV === "production" ? `wss://${window.location.hostname}` : "ws://localhost:8000";
    console.log(this.WS_URL);
  }

  getCsrfCookie = () => {
    return Cookies.get("csrftoken");
  }

  request = async (route, method, body, logoutOnElse=true, msgOnSuccess=true) => {
    let res;

    if (method === "POST" || method === "PUT") {
      res = await fetch(`${this.URL}/api/${route}`, {
        method: method,
        headers: {
          "Authorization": `access-token ${localStorage.getItem("jwt-token")}`,
          "X-CSRFToken": this.getCsrfCookie()
        },
        body: JSON.stringify({...body, csrfmiddlewaretoken: this.getCsrfCookie()})
      });
    } else {
      res = await fetch(`${this.URL}/api/${route}`, {
        method: method,
        headers: {
          "Authorization": `access-token ${localStorage.getItem("jwt-token")}`,
          "X-CSRFToken": this.getCsrfCookie()
        }
      });
    }

    const json = await res.json();
    console.log(json);

    if (json.type === "success") {
      if (msgOnSuccess) {
        toast.success(`Success: ${json.message}`, options);
      }

    } else if (json.type === "info") {
      if (msgOnSuccess) {
        toast.info(`Success: ${json.message}`, options);
      }

    } else if (json.type === "error") {
      toast.error(`Error: ${json.message}`, options);

    } else if (json.type === "generateToken") {

    } else if (json.type === "ServerError" || json.type === "Bad Request") {
      toast.error(`${json.type}: ${json.message}`, options);

    } else {
      toast.error(`${json.type}: ${json.message}`, options);
      if (logoutOnElse) {
        toast.error("Logged out !", options);
        this.handleLogout();
      }
    }

    return json
  }

  componentDidMount = async () => {
    if (this.state.loggedIn) {
      const json = await this.request("current-user/", "GET");

      if (json.type === "success") {
        this.setState({
          // APIPageStatus: "showing",
          apiKey: json.apiKey,
          apiSecret: json.apiSecret,
          brokerCode: json.brokerCode,
          brokerUsername: json.brokerUsername
        });
      }

      this.fetchBrokerAccessToken();
    }
  }

  handleLogin = async data => {
    console.log(data);
    const json = await this.request("login/", "POST", data, false);

    if (json.type === "success") {
      localStorage.setItem("jwt-token", json.access_token);

      await this.setState({
        loggedIn: true
      });

      const userJson = await this.request("current-user/", "GET");

      if (userJson.type === "success") {
        await this.setState({
          // APIPageStatus: "showing",
          apiKey: userJson.apiKey,
          apiSecret: userJson.apiSecret,
          brokerCode: userJson.brokerCode,
          brokerUsername: userJson.brokerUsername
        });
      }
      await this.fetchBrokerAccessToken();
    }
  }

  handleLogout = showToast => {
    localStorage.removeItem("jwt-token");
    this.setState({
      loggedIn: false,
      showAPIPage: false
    });

    if (showToast) {
      this.showToast("Logged out !", "error");
    }
  }

  handleRegister = async data => {
    console.log(data);
    const json = await this.request("register/", "POST", data, false);

    if (json.type === "success") {
      this.setState({
        formToShow: "login"
      });
    }
  }

  handleForgotPassword = async data => {
    console.log(data);
    await this.request("forgot-password/", "POST", data, false);
  }

  handleResetPassword = async data => {
    console.log(data);
    const json = await this.request("reset-password/", "POST", data, false);

    if (json.type === "ResetPasswordTokenExpired" || json.type === "InvalidResetPasswordToken") {
      window.location.search = "";
    }
  }

  fetchBrokerAccessToken = async () => {
    let parsed = qs.parse(window.location.search, {decode: false});
    let reqToken = parsed.request_token;

    console.log(reqToken);

    if (reqToken) {
      await this.request("set-access-token/", "POST", {requestToken: reqToken}, true, false);
      window.location.search = "";
    }

    if (this.state.loggedIn) {
      const json = await this.request("broker-token/", "GET");

      if (json.type === "generateToken") {
        confirmAlert({
          title: "Do you want to change API Settings ?",
          message: "You are being redirected to Sharekhan for logging in ! Do you want to change API settings before that ?",
          closeOnEscape: false,
          closeOnClickOutside: false,
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                this.setState({
                  // APIPageStatus: "showing",
                  showAPIPage: true,
                });
              }
            },
            {
              label: "No",
              onClick: () => {
                console.log(`Redirecting to ${json.url}`);
                window.location = json.url;
              }
            }
          ]
        });

      } else if (json.type === "success") {
        this.setState({
          username: json.username
        });
        this.getRules("active");
      }
    }
  }

  getTrades = async () => {
    const json = await this.request("trades/", "GET");

    if (json.type === "success") {
      this.setState({
        trades: json.data
      });
    }
  }

  getOrders = async () => {
    const json = await this.request("orders/", "GET");

    if (json.type === "success") {
      this.setState({
        orders: json.data
      });
    }
  }

  getPositions = async () => {
    const json = await this.request("positions/", "GET");

    if (json.type === "success") {
      this.setState({
        positions: json.data
      });
    }
  }

  getRules = async rulesType => {
    const json = await this.request(`rules/?brokerCode=${this.state.brokerCode}&rulesType=${rulesType}`, "GET", null, true, false);

    if (json.type === "success") {
      this.setState({
        rules: json.data
      });
    }
  }

  handleStartRule = async (ids) => {
    const json = await this.request("rules/start/", "PUT", {ids: ids});

    if (json.type === "success" || json.type === "Error") {
      this.getRules("active");
    }
  }

  handleStopRule = async (ids) => {
    const json = await this.request("rules/stop/", "PUT", {ids: ids});

    if (json.type === "success" || json.type === "Error") {
      this.getRules("active");
    }
  }

  showLogs = id => {
    let logsWS = new WebSocket(`${this.WS_URL}/ws/logs/${id}/?access-token=${localStorage.getItem("jwt-token")}`);
    logsWS.onopen = () => {
      console.log("Logs Websocket Connected Successfully !");
      this.showToast("Updating Logs !", "success");
    }
    logsWS.onmessage = (e) => {
      console.log(e);

      let data = JSON.parse(e.data);

      this.setState({
        logs: data.message
      });
    }
    logsWS.onclose = (e) => {
      console.log("Logs Websocket Closed successfully !");
      console.log(e);

      if (e.code === 3000) {
        this.handleLogout(true);
      }
    }

    this.setState({
      logRuleID: id,
      showLogs: true,
      logsWS: logsWS
    });
  }

  getLogs = async () => {
    const json = await this.request(`admin/logs/?id=${this.state.logRuleID}`, "GET", null, true, false);

    if (json.type === "success") {
      this.setState({
        logs: json.data
      });
    }
  }

  handleLogsModalClose = () => {
    console.log("Hidden Logs Modal");

    this.state.logsWS.close();

    this.setState({
      logRuleID: null,
      showLogs: false,
      logsWS: null
    });
  }

  getBDate = async () => {
    const json = await this.request("bdate/", "GET", null, true, false);

    if (json.type === "success") {
      this.setState({
        bdate: json.data
      });
    }
  }

  connectBDateWS = () => {
    let ws = new WebSocket(`${this.WS_URL}/ws/bdate/?access-token=${localStorage.getItem("jwt-token")}`);
    ws.onopen = () => {
      console.log("BDate Websocket Connected Successfully !");
      this.showToast("Updating BDate !", "success");
    }
    ws.onmessage = (e) => {
      console.log(e);

      let data = JSON.parse(e.data);

      this.setState({
        bdate: data.message
      });
    }
    ws.onclose = (e) => {
      console.log("BDate Websocket Closed successfully !");
      console.log(e);

      if (e.code === 3000) {
        this.handleLogout(true);
      }
    }

    this.setState({
      bdateWS: ws
    });
  }

  getSettings = async () => {
    const json = await this.request("settings/", "GET");

    if (json.type === "success") {
      return json.data;
    }
  }

  saveSettings = async state => {
    await this.request("settings/save/", "POST", state);
  }

  handleAPIEdit = e => {
    const name = e.target.name;
    const val = e.target.value;

    this.setState(prevState => {
      const newState = { ...prevState };
      newState[name] = val;
      return newState;
    });
  }

  saveAPISettings = async e => {
    e.preventDefault();
    await this.request("api-settings/save/", "POST", {apiKey: this.state.apiKey, apiSecret: this.state.apiSecret, brokerCode: this.state.brokerCode, brokerUsername: this.state.brokerUsername});

    this.setState({
      showAPIPage: false
    });

    // this.fetchBrokerAccessToken();

    const json = await this.request("get-broker-login-url/", "GET");

    console.log(`Redirecting to ${json.url}`);
    window.location = json.url;
  }

  showToast = (message, type) => {
    if (type === "success") {
      toast.success(message, options);

    } else if (type === "info") {
      toast.info(message, options);

    } else if (type === "error") {
      toast.error(message, options);
    }
  }

  showForm = form => {
    this.setState({
      formToShow: form
    });
  }

  render() {
    let page = null;

    let parsed = qs.parse(window.location.search, {decode: false});
    let resetPasswordToken = parsed.resetpasswordtoken;

    console.log(resetPasswordToken);

    if (resetPasswordToken) {
      page = <ResetPasswordForm
        showToast={this.showToast}
        resetPasswordToken={resetPasswordToken}
        handleResetPassword={this.handleResetPassword}
      />
    } else {
      if (!this.state.loggedIn) {
        if (this.state.formToShow === "login") {
          page = <LoginForm
            showForm={this.showForm}
            handleLogin={this.handleLogin}
          />
        } else if (this.state.formToShow === "register") {
          page = <RegisterForm
            showForm={this.showForm}
            showToast={this.showToast}
            handleRegister={this.handleRegister}
          />
        } else if (this.state.formToShow === "forgotPassword") {
          page = <ForgotPasswordForm
            showForm={this.showForm}
            handleLogin={this.handleLogin}
            handleForgotPassword={this.handleForgotPassword}
          />
        }

      } else {
        if (this.state.showAPIPage) {
          page = <APIPage
            apiKey={this.state.apiKey}
            username={this.state.username}
            apiSecret={this.state.apiSecret}
            handleLogout={this.handleLogout}
            brokerCode={this.state.brokerCode}
            handleAPIEdit={this.handleAPIEdit}
            saveAPISettings={this.saveAPISettings}
            brokerUsername={this.state.brokerUsername}
          />

        } else {
          page = <MainPage
            URL={this.URL}
            showToast={this.showToast}
            username={this.state.username}
            handleLogout={this.handleLogout}
            brokerCode={this.state.brokerCode}

            rules={this.state.rules}
            getRules={this.getRules}
            startRule={this.handleStartRule}
            stopRule={this.handleStopRule}

            trades={this.state.trades}
            getTrades={this.getTrades}

            orders={this.state.orders}
            getOrders={this.getOrders}

            positions={this.state.positions}
            getPositions={this.getPositions}

            logs={this.state.logs}
            getLogs={this.getLogs}
            showLogs={this.state.showLogs}
            handleShowLogs={this.showLogs}
            logRuleID={this.state.logRuleID}
            handleLogsModalClose={this.handleLogsModalClose}

            bdate={this.state.bdate}
            connectBDateWS={this.connectBDateWS}

            getSettings={this.getSettings}
            saveSettings={this.saveSettings}
          />
        }
      }
    }

    return (
      <div id="app">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {page}
        </ThemeProvider>
      </div>
    )
  }
}

export default App;
