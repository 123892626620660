import React, { Component } from "react";

// --------------------------------------------

import Rules from "./rules.jsx";
import Navbar from "./navbar.jsx";
// import APIPage from "./apiPage.jsx";
import Settings from "./settings.jsx";
import LogModal from "./logModal.jsx";
import Orderbook from "./orderbook.jsx";
import Positions from "./positions.jsx";
// import Tradebook from "./tradebook.jsx";
// import Navigation from "./navigation.js";

// --------------------------------------------

// import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
//
// import { Layout, Menu } from "antd";
// import { LogoutOutlined } from "@ant-design/icons";
//
// const { Header, Content, Sider } = Layout;

import BookIcon from "@mui/icons-material/Book";
import SettingsIcon from "@mui/icons-material/Settings";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import {
  Box,
  List,
  Drawer,
  Divider,
  ListItem,
  ListItemText,
  ListItemIcon
} from "@mui/material";

const container = window !== undefined ? () => window.document.body : undefined;

class MainPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: "rules",
      mobileOpen: false
      // BDateUpdateIntervalID: null,
    }
  }

  componentDidMount() {
    // var intervalId = setInterval(this.updateBDate, 2000);
    // this.setState({
    //   BDateUpdateIntervalID: intervalId
    // });

    this.props.connectBDateWS();
  }

  componentWillUnmount() {
    // clearInterval(this.state.BDateUpdateIntervalID);
    if (this.state.bdateWS) {
      this.state.bdateWS.close();
    }
  }

  // updateBDate = () => {
  //   this.props.getBDate();
  // }

  goto = page => {
    this.setState({
      showNavigation: false,
      page: page
    })
  }

  handleNavigationToggle = () => {
    this.setState(prevState => {
      const newState = { ...prevState };
      newState.mobileOpen = !prevState.mobileOpen;
      return newState;
    });
  }

  render() {
    let page = null;

    if (this.state.page === "rules") {
      page = <Rules
        rules={this.props.rules}
        getRules={this.props.getRules}
        stopRule={this.props.stopRule}
        showToast={this.props.showToast}
        startRule={this.props.startRule}
        brokerCode={this.props.brokerCode}
        showLogs={this.props.handleShowLogs}
        handleLogout={this.props.handleLogout}
      />

    // } else if (this.state.page === "tradebook") {
    //   page = <Tradebook
    //     trades={this.props.trades}
    //     getTrades={this.props.getTrades}
    //   />

    } else if (this.state.page === "orderbook") {
      page = <Orderbook
        orders={this.props.orders}
        getOrders={this.props.getOrders}
      />

    } else if (this.state.page === "positions") {
      page = <Positions
        positions={this.props.positions}
        getPositions={this.props.getPositions}
      />

    } else if (this.state.page === "settings") {
      page = <Settings
        getSettings={this.props.getSettings}
        saveSettings={this.props.saveSettings}
      />
    }

    let drawer = <div>
      <List>
        <ListItem>
          <ListItemText
            primary="ESG Algo"
            variant="h6"
            primaryTypographyProps={{
              fontSize: 20,
              fontWeight: "medium",
              letterSpacing: 0,
              textAlign: "center"
            }}
          />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button onClick={() => this.goto("rules")}>
          <ListItemIcon>
            <AlignVerticalBottomIcon />
          </ListItemIcon>
          <ListItemText primary="Rules" />
        </ListItem>
        <ListItem button onClick={() => this.goto("orderbook")}>
          <ListItemIcon>
            <BookIcon />
          </ListItemIcon>
          <ListItemText primary="Orderbook" />
        </ListItem>
        <ListItem button onClick={() => this.goto("positions")}>
          <ListItemIcon>
            <ImportContactsIcon />
          </ListItemIcon>
          <ListItemText primary="Positions" />
        </ListItem>
        <ListItem button onClick={() => this.goto("settings")}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          onClick={this.props.handleLogout}
        >
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>;

    return (
      <div>
        <Navbar
          bdate={this.props.bdate}
          username={this.props.username}
          handleNavigationToggle={this.handleNavigationToggle}
        />
        <Box
          sx={{ width: { md: 300 }, flexShrink: { sm: 0 } }}
        >
          <Drawer
            container={container}
            variant="temporary"
            open={this.state.mobileOpen}
            onClose={this.handleNavigationToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: {xs: "block", md: "none"},
              "& .MuiDrawer-paper": {boxSizing: "border-box", width: 300},
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: {xs: "none", md: "block"},
              "& .MuiDrawer-paper": {boxSizing: "border-box", width: 300},
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          sx={{ flexGrow: 1, p: 3, width: { md: "calc(100% - 300px)" }, ml: { md: "300px" } }}
        >
          {page}
        </Box>

        <LogModal
          logs={this.props.logs}
          ruleID={this.props.logRuleID}
          show={this.props.showLogs}
          getLogs={this.props.getLogs}
          handleClose={this.props.handleLogsModalClose}
        />
      </div>
    );

    // return (
    //   <div>
    //     <Layout>
    //       <Sider
    //         breakpoint="md"
    //         collapsedWidth="0"
    //       >
    //         <Menu theme="dark" mode="inline" defaultSelectedKeys={['4']}>
    //           <Menu.Item>Hi, {this.props.username}</Menu.Item>
    //           <Menu.Item onClick={() => this.changePage("rules")}>Rules</Menu.Item>
    //           <Menu.Item onClick={() => this.changePage("tradebook")}>Tradebook</Menu.Item>
    //           <Menu.Item onClick={() => this.changePage("orderbook")}>Orderbook</Menu.Item>
    //           <Menu.Item onClick={() => this.changePage("positions")}>Positions</Menu.Item>
    //           <Menu.Item onClick={() => this.changePage("settings")}>Settings</Menu.Item>
    //           <Menu.Item icon={<LogoutOutlined />} onClick={this.props.handleLogout}>Logout</Menu.Item>
    //         </Menu>
    //       </Sider>
    //       <Layout style={{ minHeight: '100vh' }}>
    //         <Header style={{ textAlign: 'center', color: 'white' }}>{this.props.bdate}</Header>
    //         <Content style={{ padding: 25 }}>
    //           {page}
    //         </Content>
    //       </Layout>
    //     </Layout>
    //
    //     <LogModal
    //       ID={this.props.logRuleID}
    //       logs={this.props.logs}
    //       show={this.props.showLogs}
    //       getLogs={this.props.getLogs}
    //       handleClose={this.props.handleLogsModalClose}
    //     />
    //   </div>
    // );
  }
}

export default MainPage;

        // <Navbar
        //   showNavigation={this.handleNavigationShow}
        //   bdate={this.props.bdate}
        //   getBDate={this.props.getBDate}
        // />

            // <Layout.Header style={{ padding: 0 }}>
            //   {React.createElement(this.state.showNavigation ? MenuUnfoldOutlined : MenuFoldOutlined, {
            //     className: "trigger",
            //     onClick: this.handleNavigationToggle,
            //   })}
            // </Layout.Header>

        // <Layout hasSider>
        //   <Layout.Sider
        //     collapsible
        //     collapsed={this.state.collapsed}
        //     onCollapse={this.handleNavigationToggle}
        //   >
        //     <Navigation
        //       username={this.props.username}
        //       handleLogout={this.props.handleLogout}
        //       changePage={this.changePage}
        //     />
        //   </Layout.Sider>
        //   <Layout>
        //     <Layout.Content
        //     >
        //       {page}
        //     </Layout.Content>
        //   </Layout>
        // </Layout>
