import { Component } from 'react';
// import { Container, Row, Col, Button } from "react-bootstrap";

// import { Card, Button } from "antd";

import {
  Card,
  Grid,
  Button,
  Divider,
  Typography,
  CardContent
} from "@mui/material";

import RegularTable from "./regularTable.jsx";

class Positions extends Component {
  componentDidMount() {
    this.props.getPositions();
  }

  render() {
    return (
  		<Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: 2 }}>
  			<Grid item md={10} xs={12}>
					<Card sx={{ textAlign: "center", marginBottom: 2 }} variant="outlined">
            <CardContent>
              <Typography variant="h5">Positions</Typography>
              <Divider sx={{ marginY: 2 }} />
              <RegularTable
                headers={["ID", "Rule ID", "Instrument", "Quantity", "Product Type", "Entry Price", "LTP", "P&L"]}
                rows={
                  this.props.positions.map((position, i) => {
                    return ({
                      data: [
                        position.id,
                        position.ruleID,
                        position.instrument,
                        position.qty,
                        position.productType,
                        position.entryPrice,
                        position.ltp,
                        position.pnl
                      ]
                    })
                  })
                }
              />
              <Button
                color="primary"
                disableElevation
                variant="contained"
                sx={{ marginTop: 2 }}
                onClick={this.props.getPositions}
              >
                Refresh
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default Positions;
