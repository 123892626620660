// import { Modal } from "antd";
import React, { Component } from "react";
import ScrollableFeed from "react-scrollable-feed";

import {
  Slide,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class LogModal extends Component {
  // constructor(props) {
  //   super(props);
  //
  //   this.state = {
  //     logUpdateIntervalID: null
  //   }
  // }
  //
  // componentDidMount() {
  //   var intervalId = setInterval(this.updateLogs, 1000);
  //   this.setState({
  //     logUpdateIntervalID: intervalId
  //   });
  // }
  //
  // componentWillUnmount() {
  //   clearInterval(this.state.logUpdateIntervalID);
  // }
  //
  // updateLogs = () => {
  //   if (this.props.show) {
  //     this.props.getLogs()
  //   }
  // }

  render() {
    return (
      <Dialog
        fullWidth
        maxWidth="md"
        open={this.props.show}
        onClose={this.props.handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>Logs of Rule #{this.props.ruleID}</DialogTitle>
        <DialogContent
          style={{ backgroundColor: "#121212", color: "#EDEDED", paddingTop: "1rem" }}
        >
          <ScrollableFeed className="log-window">
            {
              this.props.logs.map((log, i) => {
                console.log(`${log.status}-log`);
                return (
                  <code key={i} className={`${log.status}-log`}>{log.timestamp}: {log.log}<br /></code>
                )
              })
            }
          </ScrollableFeed>
        </DialogContent>
        <DialogActions>
          <Button
            color="info"
            disableElevation
            variant="contained"
            onClick={this.props.handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default LogModal;
