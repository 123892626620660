import { Component } from "react";
// import { Container, Row, Col, Button, FormCheck } from "react-bootstrap";

import {
  Grid,
  Card,
  Radio,
  Button,
  Divider,
  RadioGroup,
  Typography,
  CardContent,
  FormControl,
  FormControlLabel,
} from "@mui/material";

import RulesTable from "./rulesTable.jsx";

var moment = require("moment");

class Rules extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rules: [],
      selected: [],
      rulesWS: null,
      selectedAll: false,
      showRulesType: "active",
      // rulesUpdateIntervalID: null,
    }

    this.WS_URL = process.env.NODE_ENV === "production" ? `wss://${window.location.hostname}` : "ws://localhost:8000";
    console.log(this.WS_URL);
  }

  componentDidMount() {
    this.connectRulesWS();
  }

  componentDidUpdate() {
    if (!this.state.rulesWS) {
      this.connectRulesWS();
    }

    console.log(this.state);
  }

  componentWillUnmount() {
    if (this.state.rulesWS) {
      this.state.rulesWS.close();
    }

    // if (this.state.logsWS) {
    //   this.state.logsWS.close();
    // }

    this.setState({
      rulesWS: null
      // logsWS: null
    });
  }

  // componentDidMount() {
  //   var intervalId = setInterval(this.updateRules, 1000);
  //   this.setState({
  //     rulesUpdateIntervalID: intervalId
  //   });
  // }
  //
  // componentWillUnmount() {
  //   clearInterval(this.state.rulesUpdateIntervalID);
  // }

  connectRulesWS = () => {
    if (this.props.brokerCode) {
      let ws = new WebSocket(`${this.WS_URL}/ws/rules/${this.props.brokerCode}_${this.state.showRulesType}/?access-token=${localStorage.getItem("jwt-token")}`);
      ws.onopen = () => {
        console.log("Rules Websocket Connected Successfully !");
        this.props.showToast("Updating Rules !", "success");
      }
      ws.onmessage = (e) => {
        console.log(e);

        let data = JSON.parse(e.data);

        this.setState({
          rules: data.message
        });
      }
      ws.onclose = (e) => {
        console.log("Rules Websocket Closed successfully !");
        console.log(e);

        if (e.code === 3000) {
          this.props.handleLogout(true);
        }
      }

      this.setState({
        rulesWS: ws
      });
    }
  }

  // connectLogsWS = () => {
  //   let ws = new WebSocket(`ws://localhost:8000/ws/admin/logs/${this.state.logsRuleID}/?access-token=${localStorage.getItem("admin-jwt-token")}`);
  //   ws.onopen = () => {
  //     console.log("Logs Websocket Connected Successfully !");
  //     this.props.showToast("Updating Logs !", "success");
  //   }
  //   ws.onmessage = (e) => {
  //     console.log(e);
  //
  //     let data = JSON.parse(e.data);
  //
  //     this.setState({
  //       logs: data.message
  //     });
  //   }
  //   ws.onclose = (e) => {
  //     console.log("Logs Websocket Closed successfully !");
  //     console.log(e);
  //
  //     if (e.code === 3000) {
  //       this.props.handleLogout(true);
  //     }
  //   }
  //
  //   this.setState({
  //     logsWS: ws
  //   });
  // }

  refreshRulesWS = () => {
    this.state.rulesWS.close();
    this.connectRulesWS();
  }

  // updateRules = () => {
  //   this.props.getRules(this.state.showRulesType);
  // }

  checkboxChanged = (evt, i) => {
    if (evt.target.checked) {
      this.setState(prevState => ({
        selected: [ ...prevState.selected, i]
      }));
    } else {
      this.setState(prevState => {
        let selected = [ ...prevState.selected ];
        for (let j = 0; j < selected.length; j++) {
          if (selected[j] === i) {
            selected.splice(j, 1);
            break
          }
        }
        return {
          selected: selected
        }
      });
    }
  }

  selectAllChanged = evt => {
    if (evt.target.checked) {
      this.setState({
        selected: this.props.rules.map(rule => rule.ID),
        selectedAll: evt.target.checked
      });
    } else {
      this.setState({
        selected: [],
        selectedAll: evt.target.checked
      });
    }
  }

  updateShowRulesType = async e => {
    await this.setState({
      showRulesType: e.target.value
    });

    this.refreshRulesWS();
  }

  render() {
    return (
  		<Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: 2 }}>
  			<Grid item md={10} xs={12}>
					<Card sx={{ textAlign: "center", marginBottom: 2 }} variant="outlined">
            <CardContent>
              <Typography variant="h5">Rules</Typography>
              <Divider sx={{ marginY: 2 }} />
              <RulesTable
                headers={["Symbol", "Expiry", "Strikes (CE, PE, CE, PE)", "Qtys", "Traded", "Entry Prices", "LTPs", "Expected P&L", "Total P&L", "Status"]}
                rows={
                  this.state.rules.map((rule, i) => {
                    return ({
                      data: [
                        rule.symbol,
                        moment(rule.expiry).format("DD-MMM-YYYY"),
                        `${rule.strike1}, ${rule.strike2}, ${rule.strike3}, ${rule.strike4}`,
                        `${rule.qty1}, ${rule.qty2}, ${rule.qty3}, ${rule.qty4}`,
                        `${rule.traded1}, ${rule.traded2}, ${rule.traded3}, ${rule.traded4}`,
                        `${rule.tradedPrice1}, ${rule.tradedPrice2}, ${rule.tradedPrice3}, ${rule.tradedPrice4}`,
                        `${rule.ltp1}, ${rule.ltp2}, ${rule.ltp3}, ${rule.ltp4}`,
                        rule.expectedPnl,
                        rule.totalPnl,
                        rule.status
                      ],
                      started: rule.started,
                      ID: rule.id
                    })
                  })
                }
                showLogs={this.props.showLogs}
                onRuleStart={this.props.startRule}
                onRuleStop={this.props.stopRule}
                selected={this.state.selected}
                selectedAll={this.state.selectedAll}
                checkboxChanged={this.checkboxChanged}
                selectAllChanged={this.selectAllChanged}
              />
              <div style={{ textAlign: "center", marginTop: 30 }}>
                {/*<Button className="m-2" size="lg" variant="primary" onClick={e => this.props.getRules("active")}>Show Active</Button>
                <Button className="m-2" size="lg" variant="info" onClick={e => this.props.getRules("all")}>Show All</Button>*/}
                <div style={{ marginTop: 3, marginBottom: 3 }}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      name="rules-type-radio-group"
                      value={this.state.showRulesType}
                      onChange={this.updateShowRulesType}
                    >
                      <FormControlLabel value="active" control={<Radio />} label="Show Active Rules" />
                      <FormControlLabel value="all" control={<Radio />} label="Show All Rules" />
                    </RadioGroup>
                  </FormControl>
                </div>

                <Button
                  color="success"
                  disableElevation
                  variant="contained"
                  style={{ margin: 2 }}
                  onClick={e => this.props.startRule(this.state.selected)}
                >
                  Start Selected
                </Button>
                <Button
                  color="error"
                  disableElevation
                  variant="contained"
                  style={{ margin: 2 }}
                  onClick={e => this.props.stopRule(this.state.selected)}
                >
                  Stop Selected
                </Button>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default Rules;
                  // <Radio
                  //   value="active"
                  //   name="showRulesType"
                  //   id="show-active-rules-radio"
                  //   onChange={this.updateShowRulesType}
                  //   checked={this.state.showRulesType === "active"}
                  // >
                  //   Show Active Rules
                  // </Radio>
                  //
                  // <Radio
                  //   value="all"
                  //   name="showRulesType"
                  //   id="show-all-rules-radio"
                  //   onChange={this.updateShowRulesType}
                  //   checked={this.state.showRulesType === "all"}
                  // >
                  //   Show All Rules
                  // </Radio>
