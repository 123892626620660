import { Component } from "react";
// import { Form, Select, Card, Button } from "antd";
//
// const { Option } = Select;

import {
  Grid,
  Card,
  Select,
  Button,
  Divider,
  MenuItem,
  Typography,
  InputLabel,
  CardContent,
  FormControl
} from "@mui/material";

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tradingMode: "Auto",
      orderType: "Market"
    }
  }

  componentDidMount = () => {
    this.fetchSettings();
  }

  handleTradingModeEdit = e => {
    const val = e.target.value;

    this.setState(prevState => {
      const newState = { ...prevState };
      newState.tradingMode = val;
      return newState;
    })
  }

  handleOrderTypeEdit = e => {
    const val = e.target.value;

    this.setState(prevState => {
      const newState = { ...prevState };
      newState.orderType = val;
      return newState;
    })
  }

  fetchSettings = async () => {
    const settings = await this.props.getSettings();
    console.log(settings);
    this.setState({
      tradingMode: settings.tradingMode,
      orderType: settings.orderType
    })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.saveSettings(this.state);
  }

  render() {
    return (
  		<Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: 2 }}>
  			<Grid item md={10} xs={12}>
					<Card sx={{ textAlign: "center", marginBottom: 2 }} variant="outlined">
            <CardContent>
              <Typography variant="h5">Settings</Typography>
              <Divider sx={{ marginY: 2 }} />
              <form onSubmit={this.handleSubmit}>
                <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }} fullWidth>
                  <InputLabel id="trading-mode-label">Trading Mode</InputLabel>
                  <Select
                    id="trading-mode-input"
                    labelId="trading-mode-label"
                    sx={{ textAlign: "left" }}
                    value={this.state.tradingMode}
                    onChange={this.handleTradingModeEdit}
                  >
                    <MenuItem value="Auto">Auto</MenuItem>
                    <MenuItem value="Manual">Manual</MenuItem>
                  </Select>
                </FormControl>

                <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }} fullWidth>
                  <InputLabel id="order-type-label">Order Type</InputLabel>
                  <Select
                    id="order-type-input"
                    sx={{ textAlign: "left" }}
                    labelId="order-type-label"
                    value={this.state.orderType}
                    onChange={this.handleOrderTypeEdit}
                  >
                    <MenuItem value="Limit">Limit</MenuItem>
                    <MenuItem value="Market">Market</MenuItem>
                  </Select>
                </FormControl>

                <Button
                  type="submit"
                  color="primary"
                  disableElevation
                  variant="contained"
                  sx={{ marginTop: 2 }}
                >
                  Save Settings
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default Settings;
