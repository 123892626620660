// import { Component } from "react";
// import { Layout, Row, Col, Card, Form, Input, Button } from "antd";
// // import { Container, Row, Col, Card } from "react-bootstrap";
//
// const { Content } = Layout;
//
// class LoginForm extends Component {
//   constructor(props) {
//     super(props);
//
//     this.state = {
//       username: "",
//       password: ""
//     }
//   }
//
//   handleEdit = e => {
//     console.log(e);
//
//     const name = e.target.name;
//     const val = e.target.value;
//
//     this.setState(prevState => {
//       const newState = { ...prevState };
//       newState[name] = val;
//       return newState;
//     })
//   }
//
//   handleSubmit = async () => {
//     this.props.handleLogin(this.state);
//   }
//
//   render() {
//     return (
//       <Layout style={{ minHeight: '100vh' }}>
//         <Content>
//           <Row>
//             <Col xs={{span: 24, offset: 0}} md={{span: 12, offset: 6}}>
//               <Card title="ESG Algo - Login" style={{ marginTop: 10 }} type="inner">
//                 <Form onFinish={this.handleSubmit}>
//                   <Form.Item
//                     label="Username"
//                     rules={[{ required: true, message: "Username is required !" }]}
//                   >
//                     <Input
//                       name="username"
//                       onChange={this.handleEdit}
//                       value={this.state.username}
//                     />
//                   </Form.Item>
//
//                   <Form.Item
//                     label="Password"
//                     rules={[{ required: true, message: "Password is required !" }]}
//                   >
//                     <Input.Password
//                       name="password"
//                       onChange={this.handleEdit}
//                       value={this.state.password}
//                     />
//                   </Form.Item>
//
//                   <div style={{ textAlign: 'center' }}>
//                     <Button type="primary" htmlType="submit">Login</Button>
//                   </div>
//                 </Form>
//               </Card>
//             </Col>
//           </Row>
//         </Content>
//       </Layout>
//     );
//   }
// }
//
// export default LoginForm;


import { Component } from "react";
import {
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  TextField,
  Divider
} from "@mui/material";

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usernameOrEmail: "",
      password: ""
    }
  }

  handleEdit = e => {
    console.log(e);

    const name = e.target.name;
    const val = e.target.value;

    this.setState(prevState => {
      const newState = { ...prevState };
      newState[name] = val;
      return newState;
    });
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.props.handleLogin(this.state);
  }

  render() {
    return (
  		<Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: 2 }}>
  			<Grid item md={6} xs={12}>
					<Card sx={{ textAlign: "center", marginBottom: 2 }} variant="outlined">
            <CardContent>
              <Typography variant="h5">Login</Typography>
              <Divider sx={{ marginY: 2 }} />
  						<form onSubmit={this.handleSubmit}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  variant="filled"
                  sx={{ marginTop: 1 }}
                  name="usernameOrEmail"
                  label="Username/E-mail"
                  onChange={this.handleEdit}
                  value={this.state.usernameOrEmail}
                />

                <TextField
                  required
                  fullWidth
                  type="password"
                  name="password"
                  label="Password"
                  variant="filled"
                  sx={{ marginTop: 1 }}
                  value={this.state.password}
                  onChange={this.handleEdit}
                />

                <Button
                  type="submit"
                  color="primary"
                  disableElevation
                  variant="contained"
                  sx={{ marginTop: 2 }}
                >
                  Login
                </Button>
  						</form>

              <Typography
                sx={{ marginTop: 1, cursor: "pointer" }}
                onClick={() => this.props.showForm("register")}
              >
                Not a user? Register here!
              </Typography>

              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => this.props.showForm("forgotPassword")}
              >
                Forgot Password? Reset it here!
              </Typography>
            </CardContent>
					</Card>
  			</Grid>
  		</Grid>
    );
  }
}

export default LoginForm;
