import { Component } from "react";

import {
  Card,
  Grid,
  Button,
  Divider,
  TextField,
  Typography,
  CardContent
} from "@mui/material";

import SendIcon from "@mui/icons-material/Send";

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: ""
    }
  }

  handleEdit = e => {
    console.log(e);

    const name = e.target.name;
    const val = e.target.value;

    this.setState(prevState => {
      const newState = { ...prevState };
      newState[name] = val;
      return newState;
    });
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.props.handleForgotPassword(this.state);
  }

  render() {
    return (
  		<Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: 2 }}>
  			<Grid item md={6} xs={12}>
					<Card sx={{ textAlign: "center", marginBottom: 2 }} variant="outlined">
            <CardContent>
              <Typography variant="h5">Forgot Password</Typography>
              <Divider sx={{ marginY: 2}} />
  						<form onSubmit={this.handleSubmit}>
                <TextField
                  required
                  fullWidth
                  type="email"
                  name="email"
                  label="E-mail"
                  variant="filled"
                  sx={{ marginTop: 1 }}
                  value={this.state.email}
                  onChange={this.handleEdit}
                />

                <Button
                  type="submit"
                  color="primary"
                  disableElevation
                  variant="contained"
                  sx={{ marginTop: 2 }}
                  endIcon={<SendIcon />}
                >
                  Send Link
                </Button>
  						</form>

              <Typography
                sx={{ marginTop: 1, cursor: "pointer" }}
                onClick={() => this.props.showForm("login")}
              >
                Already a user? Login here!
              </Typography>

              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => this.props.showForm("register")}
              >
                New User? Register here!
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default ForgotPasswordForm;
