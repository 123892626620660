import { Component } from "react";
// import Card from "@material-tailwind/react/Card";
// import { Button, FormCheck } from "react-bootstrap";
// import CardBody from "@material-tailwind/react/CardBody";
// import VisibilityIcon from "@material-ui/icons/Visibility";
// import CardHeader from "@material-tailwind/react/CardHeader";

// import { Checkbox, Button } from "antd";
// import { EyeOutlined } from "@ant-design/icons";
import {
  Table,
  Button,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
  Typography,
  TableContainer
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

class RulesTable extends Component {
  render() {
  	return (
			<TableContainer>
				<Table sx={{ minWidth: 650 }} stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell align="center">Sr. No.</TableCell>
							<TableCell align="center">
                <Checkbox
                  id="select-all"
                  checked={this.props.selectedAll}
                  onChange={this.props.selectAllChanged}
                >
                  Select All
                </Checkbox>
							</TableCell>
							{
							  this.props.headers.map((header, i) => {
								  return (
										<TableCell align="center" key={i}>
                      <Typography variant="p" noWrap>
                        {header}
                      </Typography>
                    </TableCell>
								  );
							  })
							}
							<TableCell>Logs</TableCell>
							<TableCell>Start/Stop</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
              this.props.rows.map((row, i) => {
                return (
                  <TableRow key={i}>
										<TableCell>{row.ID}</TableCell>
										<TableCell>
                      <Checkbox
                        id={`select-rule-${i}`}
                        checked={this.props.selected.includes(row.ID)}
                        onChange={evt => this.props.checkboxChanged(evt, row.ID)}
                      />
										</TableCell>
									  {
  										row.data.map((val, j) => {
                        return (
    											<TableCell key={j}>
                            <Typography variant="p" noWrap>
      												{val}
                            </Typography>
    											</TableCell>
                        )
  										})
									  }
										{/*{
                      row.status === "stopped" ? <td className="border-b border-gray-200 align-middle font-normal text-red-500 text-sm whitespace-nowrap px-2 py-4 text-center">{row.status}</td> : <td className="border-b border-gray-200 align-middle font-normal text-green-500 text-sm whitespace-nowrap px-2 py-4 text-center">{row.status}</td>
                    }*/}
										<TableCell>
                      <IconButton color="primary" onClick={e => this.props.showLogs(row.ID)}>
                        <VisibilityIcon />
                      </IconButton>
										</TableCell>
										{
											!row.started ?
                      <TableCell>
                        <Button
                          color="success"
                          disableElevation
                          variant="contained"
                          onClick={e => this.props.onRuleStart([row.ID])}
                        >
                          Start
                        </Button>
                      </TableCell>
                      :
                      <TableCell>
                        <Button
                          color="error"
                          disableElevation
                          variant="contained"
                          onClick={e => this.props.onRuleStop([row.ID])}
                        >
                          Stop
                        </Button>
                      </TableCell>
                    }
                  </TableRow>
                )
						  })
						}
					</TableBody>
				</Table>
			</TableContainer>
  	);
  }
}

export default RulesTable;
