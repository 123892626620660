import { Component } from "react";
// import { Container, Row, Col, Button } from "react-bootstrap";

// import { Card, Button } from "antd";
import {
  Card,
  Grid,
  Button,
  Select,
  Divider,
  MenuItem,
  Typography,
  InputLabel,
  CardContent,
  FormControl
} from "@mui/material";

import RegularTable from "./regularTable.jsx";

class Orderbook extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterOrderStatus: "All"
    }
  }

  componentDidMount() {
    this.props.getOrders();
  }

  handleFilterOrderStatusChange = e => {
    const name = e.target.name;
    const val = e.target.value;

    this.setState(prevState => {
      const newState = { ...prevState };
      newState[name] = val;
      return newState;
    });
  }

  render() {
    let orders = [];

    if (this.state.filterOrderStatus === "All") {
      orders = this.props.orders;
    } else {
      this.props.orders.forEach(order => {
        if (order.orderStatus === this.state.filterOrderStatus) {
          orders.push(order);
        }
      });
    }

    return (
  		<Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: 2 }}>
  			<Grid item md={10} xs={12}>
					<Card sx={{ textAlign: "center", marginBottom: 2 }} variant="outlined">
            <CardContent>
              <Typography variant="h5">Orderbook</Typography>
              <Divider sx={{ marginY: 2 }} />

              <FormControl fullWidth sx={{ marginBottom: 1 }} variant="filled">
                <InputLabel id="filter-order-status-label">Filter Order Status</InputLabel>
                <Select
                  id="filter-order-status"
                  name="filterOrderStatus"
                  sx={{ textAlign: "left" }}
                  label="Filter Order Status"
                  labelId="filter-order-status-label"
                  value={this.state.filterOrderStatus}
                  onChange={this.handleFilterOrderStatusChange}
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Fully Executed">Fully Executed</MenuItem>
                  <MenuItem value="Cancelled">Cancelled</MenuItem>
                  <MenuItem value="Exchange Rejected">Exchange Rejected</MenuItem>
                </Select>
              </FormControl>

              <RegularTable
                headers={["Order ID", "Status", "Exchange", "Symbol", "Trade", "Type", "Product", "Price", "Qty", "Trigger Price", "Average Price", "Pending Qty", "Filled Qty", "Exchange Timestamp"]}
                rows={
                  orders.map((order, i) => {
                    return ({
                      data: [
                        order.exchOrderId,
                        order.orderStatus,
                        order.exchange,
                        order.tradingSymbol,
                        order.buySell,
                        order.priceType,
                        order.orderType,
                        order.orderPrice,
                        order.orderQty,
                        order.triggerPrice,
                        order.execPrice,
                        order.openQty,
                        order.execQty,
                        order.exchDateTime
                      ]
                    })
                  })
                }
              />
              <Button
                color="primary"
                disableElevation
                variant="contained"
                sx={{ marginTop: 2 }}
                onClick={this.props.getOrders}
              >
                Refresh
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default Orderbook;
