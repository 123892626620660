import { Component } from "react";

// import Card from '@material-tailwind/react/Card';
// import CardHeader from '@material-tailwind/react/CardHeader';
// import CardBody from '@material-tailwind/react/CardBody';

import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  TableContainer
} from "@mui/material";

class RegularTable extends Component {
  render() {
  	return (
      <TableContainer>
  			<Table sx={{ minWidth: 650 }} stickyHeader>
  				<TableHead>
  					<TableRow>
  						<TableCell>Sr. No.</TableCell>
  						{
  						  this.props.headers.map((header, i) => {
  							  return (
  									<TableCell key={i}>
                      <Typography variant="p" noWrap>
    										{header}
                      </Typography>
  									</TableCell>
  							  )
  						  })
  						}
  					</TableRow>
  				</TableHead>
  				<TableBody>
  					{
  					  this.props.rows.map((row, i) => {
                return (
                  <TableRow key={i}>
  									<TableCell>
  										{i+1}
  									</TableCell>
  								  {
  										row.data.map((val, j) => {
                        return (
    											<TableCell key={j}>
                            <Typography variant="p" noWrap>
      												{val}
                            </Typography>
    											</TableCell>
                        )
  										})
                    }
  							  </TableRow>
                )
  					  })
  					}
  				</TableBody>
  			</Table>
      </TableContainer>
  	);
  }
}

export default RegularTable;
