// import { Component } from "react";
// // import MenuIcon from '@material-ui/icons/Menu';
// // import { Nav, Navbar, Container } from "react-bootstrap";
//
// class Navbar extends Component {
//   constructor(props) {
//     super(props);
//
//     this.state = {
//       BDateUpdateIntervalID: null
//     }
//   }
//
//   componentDidMount() {
//     var intervalId = setInterval(this.updateBDate, 2000);
//     this.setState({
//       BDateUpdateIntervalID: intervalId
//     });
//   }
//
//   componentWillUnmount() {
//     clearInterval(this.state.BDateUpdateIntervalID);
//   }
//
//   updateBDate = () => {
//     this.props.getBDate();
//   }
//
//   render() {
//     return (
//       // <Navbar bg="dark" variant="dark" expand="sm">
//       //   <Container fluid>
//       //     <Navbar.Brand>Hi, {this.props.username}</Navbar.Brand>
//       //     <Navbar.Toggle aria-controls="navbar-nav" />
//       //     <Navbar.Collapse id="navbar-nav">
//       //       <Nav className="me-auto">
//       //         <Nav.Link className="text-white">Current Time: {this.props.bdate}</Nav.Link>
//       //       </Nav>
//       //       <Nav>
//       //         <button className="btn btn-light mx-2" onClick={this.props.showNavigation}><MenuIcon></MenuIcon> Menu</button>
//       //         <button className="bg-red-600 hover:bg-red-800 text-white font-bold py-2 px-3 rounded justify-content-end" onClick={this.props.handleLogout}>Logout</button>
//       //       </Nav>
//       //     </Navbar.Collapse>
//       //   </Container>
//       // </Navbar>
//       <div></div>
//     );
//   }
// }
//
// export default Navbar;


import { Component } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Toolbar, IconButton, Typography, Container } from "@mui/material";

class Navbar extends Component {
  render() {
    return (
      <AppBar
        position="static"
        elevation={0}
        sx={{
          width: {md: "calc(100% - 300px)"},
          ml: {md: "300px"},
        }}
        color="secondary"
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              Hi, {this.props.username} ({this.props.bdate})
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton size="large" onClick={this.props.handleNavigationToggle} color="inherit">
                <MenuIcon />
              </IconButton>
            </Box>

            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            >
              Hi, {this.props.username} ({this.props.bdate})
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
}

export default Navbar;

              // <Menu
              //   id="menu-appbar"
              //   anchorEl={this.state.anchorElNav}
              //   anchorOrigin={{
              //     vertical: "bottom",
              //     horizontal: "left",
              //   }}
              //   keepMounted
              //   transformOrigin={{
              //     vertical: "top",
              //     horizontal: "left",
              //   }}
              //   open={Boolean(this.state.anchorElNav)}
              //   onClose={this.handleCloseNavMenu}
              //   sx={{
              //     display: { xs: "block", md: "none" },
              //   }}
              // >
              //   <MenuItem key="Algo" onClick={() => this.goto("algo")}>
              //     <Typography textAlign="center">Algo</Typography>
              //   </MenuItem>
              //   <MenuItem key="Orderbook" onClick={() => this.goto("orderbook")}>
              //     <Typography textAlign="center">Orderbook</Typography>
              //   </MenuItem>
              //   <MenuItem key="Logs" onClick={() => this.goto("logs")}>
              //     <Typography textAlign="center">Logs</Typography>
              //   </MenuItem>
              //   <MenuItem key="Settings" onClick={() => this.goto("settings")}>
              //     <Typography textAlign="center">Settings</Typography>
              //   </MenuItem>
              // </Menu>
