import { Component } from "react";

import {
  Card,
  Grid,
  Button,
  Divider,
  InputLabel,
  IconButton,
  Typography,
  CardContent,
  FormControl,
  FilledInput,
  InputAdornment
} from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newPassword: "",
      confirmNewPassword: "",
      showNewPassword: false,
      showConfirmNewPassword: false
    }
  }

  handleEdit = e => {
    console.log(e);

    const name = e.target.name;
    const val = e.target.value;

    this.setState(prevState => {
      const newState = { ...prevState };
      newState[name] = val;
      return newState;
    });
  }

  handleSubmit = async e => {
    e.preventDefault();

    if (this.state.newPassword !== this.state.confirmNewPassword) {
      this.props.showToast("New Password must match the confirmation !", "error");
    } else {
      this.props.handleResetPassword({ ...this.state, "resetPasswordToken": this.props.resetPasswordToken});
    }
  }

  handleShowNewPasswordToggle = e => {
    this.setState(prevState => {
      const newState = { ...prevState };
      newState.showNewPassword = !prevState.showNewPassword;
      return newState;
    });
  }

  handleShowConfirmNewPasswordToggle = e => {
    this.setState(prevState => {
      const newState = { ...prevState };
      newState.showConfirmNewPassword = !prevState.showConfirmNewPassword;
      return newState;
    });
  }

  handleMouseDownPassword = e => {
    e.preventDefault();
  }

  render() {
    return (
  		<Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: 2 }}>
  			<Grid item md={6} xs={12}>
					<Card sx={{ textAlign: "center", marginBottom: 2 }} variant="outlined">
            <CardContent>
              <Typography variant="h5">Reset Password</Typography>
              <Divider sx={{ marginY: 2}} />
  						<form onSubmit={this.handleSubmit}>
                <FormControl required sx={{ marginTop: 1 }} variant="filled" fullWidth>
                  <InputLabel htmlFor="new-password">New Password</InputLabel>
                  <FilledInput
                    required
                    id="new-password"
                    type={this.state.showNewPassword ? "text" : "password"}
                    name="newPassword"
                    onChange={this.handleEdit}
                    value={this.state.newPassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={this.handleShowNewPasswordToggle}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="New Password"
                  />
                </FormControl>

                <FormControl required sx={{ marginTop: 1 }} variant="filled" fullWidth>
                  <InputLabel htmlFor="confirm-new-password">Confirm New Password</InputLabel>
                  <FilledInput
                    required
                    id="confirm-new-password"
                    type={this.state.showConfirmNewPassword ? "text" : "password"}
                    name="confirmNewPassword"
                    onChange={this.handleEdit}
                    value={this.state.confirmNewPassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={this.handleShowConfirmNewPasswordToggle}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showConfirmNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                  />
                </FormControl>

                <Button
                  type="submit"
                  color="primary"
                  disableElevation
                  variant="contained"
                  sx={{ marginTop: 2 }}
                  startIcon={<SaveIcon />}
                >
                  Reset Password
                </Button>
  						</form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default ResetPasswordForm;
