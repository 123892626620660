import { Component } from "react";

import {
  Card,
  Grid,
  Button,
  Divider,
  TextField,
  Typography,
  InputLabel,
  IconButton,
  CardContent,
  FormControl,
  FilledInput,
  InputAdornment
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

class RegisterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      apiKey: "",
      apiSecret: "",
      brokerCode: "",
      brokerUsername: "",
      showPassword: false,
      showConfirmPassword: false
    }
  }

  handleEdit = e => {
    console.log(e);

    const name = e.target.name;
    const val = e.target.value;

    this.setState(prevState => {
      const newState = { ...prevState };
      newState[name] = val;
      return newState;
    });
  }

  handleSubmit = async e => {
    e.preventDefault();

    if (this.state.password !== this.state.confirmPassword) {
      this.props.showToast("Password must match the confirmation !", "error");
    } else {
      this.props.handleRegister(this.state);
    }
  }

  handleShowPasswordToggle = e => {
    this.setState(prevState => {
      const newState = { ...prevState };
      newState.showPassword = !prevState.showPassword;
      return newState;
    });
  }

  handleShowConfirmPasswordToggle = e => {
    this.setState(prevState => {
      const newState = { ...prevState };
      newState.showConfirmPassword = !prevState.showConfirmPassword;
      return newState;
    });
  }

  handleMouseDownPassword = e => {
    e.preventDefault();
  }

  render() {
    return (
  		<Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: 2 }}>
  			<Grid item md={6} xs={12}>
					<Card sx={{ textAlign: "center", marginBottom: 2 }} variant="outlined">
            <CardContent>
              <Typography variant="h5">Register</Typography>
              <Divider sx={{ marginY: 2}} />
  						<form onSubmit={this.handleSubmit}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  name="username"
                  label="Username"
                  variant="filled"
                  sx={{ marginTop: 1 }}
                  onChange={this.handleEdit}
                  value={this.state.username}
                />

                <TextField
                  required
                  fullWidth
                  type="email"
                  name="email"
                  label="E-mail"
                  variant="filled"
                  sx={{ marginTop: 1 }}
                  value={this.state.email}
                  onChange={this.handleEdit}
                />

                <FormControl required sx={{ marginTop: 1 }} variant="filled" fullWidth>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <FilledInput
                    required
                    id="password"
                    type={this.state.showPassword ? "text" : "password"}
                    name="password"
                    onChange={this.handleEdit}
                    value={this.state.password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={this.handleShowPasswordToggle}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>

                <FormControl required sx={{ marginTop: 1 }} variant="filled" fullWidth>
                  <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
                  <FilledInput
                    required
                    id="confirm-password"
                    type={this.state.showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    onChange={this.handleEdit}
                    value={this.state.confirmPassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={this.handleShowConfirmPasswordToggle}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                  />
                </FormControl>

                <TextField
                  required
                  fullWidth
                  type="text"
                  name="apiKey"
                  label="API Key"
                  variant="filled"
                  sx={{ marginTop: 1 }}
                  value={this.state.apiKey}
                  onChange={this.handleEdit}
                />

                <TextField
                  required
                  fullWidth
                  type="text"
                  name="apiSecret"
                  variant="filled"
                  label="API Secret"
                  sx={{ marginTop: 1 }}
                  onChange={this.handleEdit}
                  value={this.state.apiSecret}
                />

                <TextField
                  required
                  fullWidth
                  type="text"
                  variant="filled"
                  name="brokerCode"
                  label="Broker Code"
                  sx={{ marginTop: 1 }}
                  onChange={this.handleEdit}
                  value={this.state.brokerCode}
                />

                <TextField
                  required
                  fullWidth
                  type="text"
                  variant="filled"
                  name="brokerUsername"
                  sx={{ marginTop: 1 }}
                  label="Broker Username"
                  onChange={this.handleEdit}
                  value={this.state.brokerUsername}
                />

                <Button
                  type="submit"
                  color="primary"
                  disableElevation
                  variant="contained"
                  sx={{ marginTop: 2 }}
                >
                  Register
                </Button>
  						</form>

              <Typography
                sx={{ marginTop: 1, cursor: "pointer" }}
                onClick={() => this.props.showForm("login")}
              >
                Already a user? Login here
              </Typography>

              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => this.props.showForm("forgotPassword")}
              >
                Forgot Password? Reset it here
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default RegisterForm;
